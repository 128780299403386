import Image1 from '../../assets/tictactoe.jpg'
import Image2 from '../../assets/menu.jpg'
import Image3 from '../../assets/quiz.jpg'
import Image4 from '../../assets/colorgenerator.jpg'
import Image5 from '../../assets/cocktails.jpg'
import Image6 from '../../assets/grocery.jpg'
import Image7 from '../../assets/hackernews.jpg'
import Image8 from '../../assets/palmacology.jpg'
import Image9 from '../../assets/furniturestore .jpg'
import Image10 from '../../assets/githubuser.jpg'

const data = [
    
   // Simple PROJECTS
    {
        
            id: 4,
            category: 'Simple Projects',
            image: Image3,
            title: "MultipleChoiceQuiz",
            desc: "User can choose the number of questions,the category, and the diffuculty of the quiz",
            tech: "Tech : React.js",
            demo: 'https://soft-lebkuchen-6cf4aa.netlify.app/',
            github: 'https://github.com/SerwinKastaneer05/MultipleChoiceQuiz'
        },
        //Large-Scale Projects
    {
        id: 2,
        category: 'Large-Scale Projects',
        image: Image8,
        title: "Palmacology",
        desc: "Our website helps students to memorize drug names and types which, coincidentally, is one of the most fundamental and hardest part of Pharmacology ",
        tech: "Tech: React.js,Node.js,Express.js,MongoDB,Firebase",
        // demo: 'http://egatortutorials.com',
        github: 'https://github.com/SerwinKastaneer05/Palmacology-' 
    },
    {
        id: 9,
        category: 'Large-Scale Projects',
        image: Image9,
        title: "Furniture Store (E-Commerce)",
        desc: "Tech: React.js",
        demo: 'https://earnest-halva-95bad6.netlify.app/',
        github: 'https://github.com/SerwinKastaneer05/FurnitureStoreApp' 
    },
    {
        id: 10,
        category: 'Large-Scale Projects',
        image: Image10,
        title: "Github Users",
        desc: " Under Contruction",
        tech: "React.js",
        // demo: 'http://egatortutorials.com',
      github: 'https://github.com/SerwinKastaneer05/GitHub-SearchUsersApp' 
    },
        {
            id: 6,
            category: 'Simple Projects',
            image: Image5,
            title: "CocktailsMenu",
            desc: " Used https://www.thecocktaildb.com/api/jspn/v1/1/search.php?s= to get data about the cocktails and it's information",
            tech: "Tech : React.js",
            demo: 'https://fabulous-sprinkles-a0169d.netlify.app/',
            github: 'https://github.com/SerwinKastaneer05/CocktailsMenu'
        },
        {
            id:7,
            category: 'Simple Projects',
            image: Image6,
            title: "Grocery List",
            desc: "",
            tech: "Tech : React.js",
            demo: 'https://resilient-kitten-d0eecb.netlify.app/',
            github: 'https://github.com/SerwinKastaneer05/GroceryList-'
        },
        {
            id: 8,
            category: 'Simple Projects',
            image: Image7,
            title: "Hacker News",
            tech: "Tech : React.js",
            desc: "",
            demo: 'https://meek-bonbon-08296b.netlify.app/',
            github: 'https://github.com/SerwinKastaneer05/HackerNews-'
        },
        {
        id: 1,
        category: 'Simple Projects',
        image: Image1,
        title: "TicTacToe Game",
        desc: "TicTacToe game against Computer ",
        tech: "Tech : HTML,CSS/Bootstrap, Javascript, jQuery",
        demo: 'https://iridescent-marzipan-9fc25a.netlify.app',
        github: 'https://github.com/SerwinKastaneer05/TicTacToe'
    },
    {
        id: 3,
        category: 'Simple Projects',
        image: Image2,
        title: "Restaurant-Menu",
        desc: "",
        tech: "Tech : React.js",
        demo: 'https://splendorous-crostata-707c03.netlify.app',
        github: 'https://github.com/SerwinKastaneer05/Restaurant-Menu'
    },
   
    {
        id: 5,
        category: 'Simple Projects',
        image: Image4,
        title: "Color Generator",
        desc: "",
        tech: "Tech : React.js",
        demo: 'https://gregarious-dusk-830165.netlify.app/',
        github: 'https://github.com/SerwinKastaneer05/ColorGenerator'
    },
    
   
]


export default data