import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {MdLocationPin} from 'react-icons/md'
import {BiHappyHeartEyes} from 'react-icons/bi'


const data = [
    {id: 1, icon: <FaAward/>, title: 'Experience', desc: '2+ Years '},
    {id: 2, icon: <TbBooks/>, title: 'Projects', desc: '10+ Completed'},
    {id: 3, icon: <MdLocationPin/>, title: 'Location', desc: 'West Palm Beach, Florida'},
    ]



    export default data;