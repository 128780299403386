import AboutImage from '../../assets/me3.JPG'
import SerwinKastaneer_Resume from '../../assets/SerwinKastaneer_Resume.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About Image" />
                </div>
            </div>
            <div className="about__right">
                <h1>About Me</h1>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                
                <p>
                I am proficient in HTML, CSS, JavaScript, React JS, Node.js, Git, C#, C++, SQL and computer hardware. I keep myself fully updated with new technological trends. Plus, I communicate with intent. I’m good as an individual and better in a team. I'm confident that my determined personality and a careful eye for detail would bring a progressive and flexible attitude to your organization.  Check out my resume below!
                </p>
                <br></br>
                fun fact : I took 18  credits  every semester while working full-time to graduate in time.
                <a href={SerwinKastaneer_Resume} download className='btn primary'>Download CV <HiDownload/></a>
            </div>
        </div>
    </section>
  )
}

export default About